// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import Gallery from '@bodhi-project/components/lib/gatsby/Gallery'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/wrappers/StandardPage'
import seoHelper from '../methods/seoHelper'

// import Link from '../components/Link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageData = {
  pageTitle: 'Adult Learning | Learning Wings Education System',
  nakedPageSlug: 'adult-learning',
  pageAbstract:
    'Learning Wings Education Systems is the only authorized centre (IN364) in Punjab for conducting Qualifications for Cambridge Assessment English, a department of the University of Cambridge and part of Cambridge Assessment Group, which provides world’s leading range of qualifications for learners and teachers for English.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logos/mobile-logo.png" }) {
      ...max300
    }
    desktopLogo: file(relativePath: { eq: "logos/desktop-logo.png" }) {
      ...max300
    }
    logo: file(relativePath: { eq: "logos/logo-square.png" }) {
      ...max300
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <StandardPage className="page" seoData={seoData} {...props}>
    <div className="small-default-container">
      <h1 class="shadow" data-shadow="Adult Learning">
        Adult Learning
      </h1>
      <div style={{ maxWidth: '50rem' }}>
        <p>
          Each individual is a learner in a way or another at each phase of
          life, in support to this statement, Cambridge Sparkling Stars’
          learning centre presents development opportunities for people of all
          age groups starting, six months’ child to eighty years old person. It
          contributes to the Physical, Emotional, Cognitive and Social
          development of the Learners. A passionate team of expert professionals
          at our learning centres works for producing best-in-class learning
          programmes to pioneer learning through emotional, physical, creative
          and intellectual growth of its learners.
        </p>
        <h2 className="mask-h4">English Language Training Centre(ELTC)</h2>
        <p>
          The aspirants are trained to learn and improve their ability to
          understand the English Language listening, reading, writing and
          speaking skills through the courses designed by Cambridge English UK.
          The training sessions are customized to cater to the need of learning
          English for academic, professional, business and international
          immigration purposes.
        </p>
        <h2 className="mask-h4">Art Education Centre</h2>
        <p>
          Art education is provided through Trinity College of speech and drama.
          The study strands includes speech and drama, individual acting skills,
          group performance, Shakespeare, oral speaking, communication skills,
          music theatre and performance arts.
        </p>
        <h2 className="mask-h4">Story Telling Centre</h2>
        <p>
          Storeytelling training for parents as well as teachers based on the
          resources and methods designed by Kathalya Bangalore. It aims to fill
          the vacuum of quality stories and creates new standards in children’s
          education. The courses are available to people of all ages. It also
          opens the avenues for professionals not just into the field of telling
          stories to children, but to deliver motivational workshops for working
          professionals in the corporate.
        </p>
        <h2 className="mask-h4">Teacher Training Centre</h2>
        <p>
          Continuous professional development opportunities as per the guidance
          and course material designed by international bodies like Cambridge
          University UK, Newman University, Wolverhampton University, Early
          childhood Association.
        </p>
        <h2 className="mask-h4">Human Resources Management</h2>
        <p>
          Human Resources are the most crucial element of a school management
          system. Learning Wings shares with you an extensive know-how in the
          areas of HR management.
        </p>
        <h2 className="mask-h4">Foreign Language Trainings Centre(FLTC)</h2>
        <p>
          People planning to move internationally and require to learn German,
          French and Spanish can be benefited at Cambridge Sparkling Stars
          Learning centers with trainings from language experts and well
          thought/practices course material.
        </p>
        <h2 className="mask-h4">Music And Dance Training Centre</h2>
        <p>
          Modern and traditional styles of dancing and music are taught to all
          young learners. Separate batches are available for different age
          groups.
        </p>
        <h2 className="mask-h4">Sports & Fitness Centre</h2>
        <p>
          Dedicated classes are organized in small groups to keep our learners
          fit and active. We indulge them in games and physical fitness
          activities like Yoga, badminton, tennis, Aerobics etc and encourage
          them to be fit.
        </p>
        <h2 className="mask-h4">Parents Support Centre</h2>
        <p>
          This center for parents is a resource center for imparting them with
          knowledge and guidance about day to day parenting.
        </p>
        <h2 className="mask-h4">Mathematics Training Centre</h2>
        <p>
          Stuck with some tricky Algebra or tedious calculations? Come to our
          Mathematic training Centres that provide you useful knowledge and tips
          on Vedic Math, Abacus and math games.
        </p>
      </div>
    </div>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
